<template>
  <v-container>
    <h3>{{ $t("message.create-item-title") }}</h3>
    <v-card flat>
      <v-col class="pr-2 pb-2" cols="12">
        <form>
          <v-row>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.shop") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-autocomplete
                v-model="formItem.store_id"
                outlined
                :items="shopList"
                :error-messages="shopErrors"
                @input="$v.formItem.store_id.$touch()"
                @blur="$v.formItem.store_id.$touch()"
                required
                item-text="store_name"
                item-value="id"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.label-import-csv") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-file-input
                class="file-input-class"
                id="file-input"
                ref="fileImg"
                v-model="formItem.file"
                type="file"
                accept=".csv"
                outlined
                required
              ></v-file-input>
            </v-col>
          </v-row>
          <div class="d-flex flex-row-reverse mt-3 me-0" tile>
            <div class="m-2">
              <v-btn color="primary" type="submit" @click.prevent="insertItem">
                {{ $t("message.submit-button") }}
              </v-btn>
            </div>
            <div>
              <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                {{ $t("message.back-button") }}
              </v-btn>
            </div>
          </div>
        </form>
      </v-col>
    </v-card>

    <v-card flat class="mt-7">
      <form>
        <v-row class="me-2">
          <v-col cols="12" md="2">
            {{ $t("message.item-code") }}
          </v-col>
          <v-col cols="12" md="10">
            <v-autocomplete
              v-model="value"
              :items="itemCodes"
              item-text="item_name"
              item-value="item_code"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            {{ $t("message.image-zip") }}
          </v-col>
          <v-col cols="12" md="10">
            <v-file-input
              class="file-input-class"
              id="file-input"
              ref="fileImg"
              v-model="zipImage"
              accept=".zip"
              type="file"
              outlined
            ></v-file-input>
          </v-col>
          <div class="d-flex flex-row-reverse mt-3 me-0" tile>
            <div class="m-2">
              <v-btn color="primary" type="submit" @click.prevent="submitImage">
                {{ $t("message.submit-button") }}
              </v-btn>
            </div>
            <div>
              <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                {{ $t("message.back-button") }}
              </v-btn>
            </div>
          </div>
        </v-row>
      </form>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "AddItemCsv",
  mixins: [validationMixin],
  validations: {
    formItem: {
      store_id: { required },
      file: { required },
    },
  },
  data() {
    return {
      formItem: {
        store_id: "",
        file: null,
      },
      value: null,
      zipImage: null,
    };
  },
  computed: {
    shopList() {
      return this.$store.state.shop_module.shopAllResult;
    },
    shopErrors() {
      const errors = [];
      if (!this.$v.formItem.store_id.$dirty) return errors;
      !this.$v.formItem.store_id.required &&
        errors.push(this.$t("message.err-msg-shop"));
      return errors;
    },
    itemCodes() {
      return this.$store.state.itemList_module.itemCodeList;
    },
  },
  methods: {
    insertItem() {
      this.$store.dispatch("itemList_module/insertItemCsv", this.formItem);
    },
    fetchShopList() {
      return this.$store.dispatch("shop_module/fetchShopAll");
    },
    async dispatchItemCode() {
      await this.$store.dispatch("itemList_module/fetchItemCode");
    },
    submitImage() {
      if (this.zipImage == null || this.value == null) {
        Swal.fire({
            icon: 'error',
            position: "top",
            text: "Invalid input",
            showConfirmButton: false,
            timer: 1500
          })
      } else {
        this.$store.dispatch("itemList_module/uploadImageZip", {
          file: this.zipImage,
          item_code: this.value,
        });
      }
    },
  },
  created() {
    this.fetchShopList();
    this.dispatchItemCode();
  },
};
</script>

<style>
.v-text-field .v-input__control .v-input__slot {
  min-height: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.file-input-class .v-input__prepend-outer {
  margin-top: 0px !important;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
</style>
